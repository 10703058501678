<template>
  <!--  :style='loading && { blur: "18px", filter: "blur(1px)"  }'-->

  <div
    class="card text-left"
    style="background: linear-gradient(#1c1c1c, #000000);margin: 5px;min-height: 310px"
  >
    <div class="text-center" >
      <div v-if="withTitle">
        <h3
          slot="header"
          style="font-size: 1.9rem;margin-top: 20px"

        >
          {{ event.title.split("@")[0] }}
        </h3>
        <p
          style="
          margin-top: -25px;
          font-size: 0.8rem;
          padding: 15px;
          font-weight: normal;
          color: #b5b5b5 !important;
          text-transform: capitalize;
        "
          v-if="event.metadata.caption"
        >
          {{ event.metadata.caption }}
        </p>
      </div>

      <slot>
        <div v-if="withLink">
          <router-link
            :to="event.loading ? {} : { name: this.type, params: { slug: event.slug} }"
            class="font-weight-bold"
          >
            <ResponsiveImage :image="event.metadata.main_image.imgix_url" ws="220" hs="160" wl="300" hl="350" style="margin-top: 30px"></ResponsiveImage>
          </router-link>
        </div>
        <div v-if="!withLink">
            <ResponsiveImage :image="event.metadata.main_image.imgix_url" ws="220" hs="160" wl="300" hl="350" style="margin-top: 30px"></ResponsiveImage>
        </div>


        <EventBrand :title="event.title"></EventBrand>
        <br>

        <hr class="brand-title-black" style="height: 1px"/>
        <div style="margin-top: 5px" class="text-center">

          <EventVenue :title="event.title" icon="true"></EventVenue>
          <h4
            class="tulum-party-subtitle"
            style="text-transform: none; font-size: 0.9rem;font-weight: normal;margin: 3px;"
          >
            {{
              (event.metadata.date + ' ' + event.metadata.start_time)
                | formattedDate
            }}
          </h4>
          <h5 style="font-size: 0.7rem">DOORS OPEN {{ event.metadata.start_time }}</h5>
        </div>
      </slot>

      <div
        slot="content"
        style="padding: 2rem; font-size: 0.8rem; margin-top: 15px"
        v-if="withContent"
      >
        <div
          style="font-size: 1rem !important;"
          v-if="event.content"
          v-html="event.content"
        ></div>
      </div>


      <router-link
        :class="this.loading && 'disabled' "
        v-if="!event.metadata.magicEvent"
        :to="
                      event.metadata.magicEvent
                        ? { path: event.metadata.link + '?event='+event.slug}
                        : { name: 'Event', params: { slug: event.slug } }
                    "        class="btn btn-primary btn-sm"
      >
        EVENT DETAILS
      </router-link>


      <hr/>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import EventVenue from "@/components/EventCard/EventVenue";
import EventBrand from "@/components/EventCard/EventBrand";
import ResponsiveImage from '@/components/HeadImage/ResponsiveImage';

export default {
  name: 'EventCard',
  components: { ResponsiveImage, EventBrand, EventVenue},
  filters: {
    formattedDate: function attachPrettyDate(start_time) {
      const partyDate = start_time;
      const format = 'dddd, MMMM Do ';
      const formatOptions = {
        sameDay: function () {
          return moment(`${partyDate}`, 'YYYY-MM-DD HH:mm', true).isValid()
            ? `[${this.calendar()}] `
            : `[Today ~] ${format}`;
        },
        nextDay: function () {
          return moment(`${partyDate}`, 'YYYY-MM-DD HH:mm', true).isValid()
            ? `[${this.calendar()} / ] ${format}`
            : `[Tomorrow ~] ${format}`;
        },
        nextWeek: format,
        sameElse: format,
      };

      return moment(partyDate).calendar(null, formatOptions);
    },
  },
  props: {
    event: {
      type: Object,
    },
    idx: {
      type: Number,
    },
    withContent: {
      type: Boolean,
      default: false
    },
    withTitle: {
      type: Boolean,
      default: true
    },
    withLink: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
    },
    type: {
      type: String,
      default: 'Event'
    }
  }
};
</script>

