<template>
  <div class="text-left" >

    <!--    {{event}}-->
      <router-link :to="{path: link}" @click.native="logClick"
                   class=" card-animation-on-hover-no-bg">

        <img
          v-if="this.image"
          v-lazy=" this.image + '?q=&w=50&h=50&fit=clamp' "
          style="float:left;margin-right: 30px;margin-left: 20px; z-index: 12; box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);border-radius: 40px;border: 1px solid #b69603"
          v-bind:alt="this.event.title"
          height="60px"
          width="60px"
        />
      </router-link>


      <div
        style="display: inline;vertical-align: top;color: white;position:relative ; line-height: 0.7rem;">
        <router-link :to="{path: event.metadata.artist_link}" @click.native="logClick">

          <span style="font-weight: 700 !important;">{{ eventTitle }}</span>
        </router-link>
        <router-link :to="{path: link}" @click.native="logClick">

        <p v-if="event.metadata.start_time">

          {{ getMonthShort(event.metadata.date + 'T' + event.metadata.start_time) }}
          {{ getDayNumeric(event.metadata.date + 'T' + event.metadata.start_time) }},
          {{ getYear(event.metadata.date + 'T' + event.metadata.start_time) }}

        </p>

        <p v-if="!event.metadata.start_time">
          {{ getDay(event.metadata.date + 'T' + '00:00') }},
          {{ getMonthShort(event.metadata.date + 'T' + '00:00') }}
          {{ getDayNumeric(event.metadata.date + 'T' + '00:00') }}
        </p>
        <small style="margin-top: -50px;color: silver;text-transform: uppercase"
        >{{ eventLocation }}
        </small>
        </router-link>
      </div>
  </div>
</template>
<script>
import '@/common/eventDateUtils/';
import { eventDateUtils } from '@/common/eventDateUtils';

export default {
  name: 'MiniArtistCard',
  components: {},
  mixins: [eventDateUtils],
  props: {
    event: {
      type: Object,
      default: () => {
        return { metadata: { image_url: '-- --' } };
      }
    },
    image: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {
      expandTab: false
    };
  },
  computed: {
    eventTitle: {
      get() {
        return (
          this.event.title ? this.event.title.split('@')[0] : ''
        );
      }
    },
    link: {
      get() {
        return (
          this.event.metadata.artist_link ? this.event.metadata.artist_link : this.event.metadata.event_link
        );
      }
    },
    eventLocation: {
      get() {
        return (
          this.event.title ? this.event.title.split('@')[1].split('|')[0] : ''
        );
      }
    }
  },
  methods: {
    expand: function expand() {
      this.expandTab = !this.expandTab;
    },
    logClick: function logClick() {
      this.$rollbar.info('Artist MiniCard', this.event.title);
    }
  }
};
</script>

