<template>
  <div class="card"
       :style="{ backgroundColor: `${primaryColor}`}"
       :class="align">
    <router-link :to="{path:this.highlight.metadata.djschedule.metadata.event_link }"

                 class="card-animation-on-hover-no-bg" >
      <ResponsiveImage  align="text-center" :clamp="true"
                        hs="250" ws="550"
                        wl="500" hl="300"
                        :image="this.highlight.metadata.main_image.imgix_url" title="DJ Schedule"
                       caption="Tulum, Mexico" :color="secondaryColor"></ResponsiveImage>
      <br>
      <h3 class="section-header">{{this.highlight.metadata.djschedule.title.split("@")[0]}}</h3>
      <EventVenue :title="this.highlight.metadata.djschedule.title" icon="true"></EventVenue>

      <p class="alt-font" style="font-size: 1.1rem">{{niceDate(this.highlight.metadata.djschedule)}}</p>

    </router-link>
  </div>
</template>
<script>
import ResponsiveImage from "@/components/HeadImage/ResponsiveImage"
import {COSMIC_BASE_URL} from "@/common/request";
import moment from "moment";
import { eventDateUtils } from '@/common/eventDateUtils';
import EventVenue from '@/components/EventCard/EventVenue';

export default {
  name: 'HotThisWeek',
  mixins: [eventDateUtils],
  created: function(){
    fetch( encodeURI( `${COSMIC_BASE_URL}&props=title,slug,metadata.main_image,metadata.djschedule&query={"type":"artisthighlight","slug":"highlight" }` ) ).then((response) => {
      response.json().then((r) => {
        this.highlight = r.objects[0];
      });
    });
  },
  data: () => {
    return {
      highlight: {metadata: {main_image: {}, djschedule: {title: 'loading @ ...', metadata: {main_image: {}}}}},
    }
  },
  components: { EventVenue, ResponsiveImage},
  props: {
    primaryColor: {
      type: String,
      default: ''
    },
    secondaryColor: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'text-left'
    }
  },
  computed: {
    highlightEventDate: {
      get() {
        moment()
        return this.highlight.metadata.djschedule.metadata.date && moment(this.highlight.metadata.djschedule.metadata.date).calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'dddd',
          sameElse: 'DD/MM/YYYY'
        })
      }
    }
  }
}
</script>
