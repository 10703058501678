export const eventDateUtils = {
  methods: {
    getOrdinalDay() {
      const day = this.getDayNumeric();
      if(day < 1 || day > 31) {
        return 'Invalid day: ' + day;
      }

      let suffix = 'th';
      if (day === 1 || day === 21 || day === 31) {
        suffix = 'st';
      } else if (day === 2 || day === 22) {
        suffix = 'nd';
      } else if (day === 3 || day === 23) {
        suffix = 'rd';
      }
      return `${this.getDayOfWeek() + ' ' + day + suffix}`;
    },
    getDay(date) {
      const d = new Date(date || Date.now());
      return d.toLocaleString('en-US', { weekday: 'short' });
    },
    getDayNumeric(date) {
      const d = new Date(date || Date.now());
      return String(d.getDate()).padStart(2, '0');
    },
    getMonthShort(date) {
      const d = new Date(date || Date.now());
      return d.toLocaleString('en-US', { month: 'short' });
    },
    getYear(date) {
      const d = new Date(date);
      return d.getFullYear();
    },
    getCurrentMonth() {
      const d = new Date();
      return d.toLocaleString('en-US', { month: 'long', year: 'numeric' });
    },
    getDayOfWeek(date) {
      const d = new Date(date || Date.now());
      return d.toLocaleString('en-US', { weekday: 'long' });
    },
    getMonth(date) {
      const d = new Date(date || Date.now());
      return d.toLocaleString('en-US', { month: 'long' });
    },
    getNextMonth() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth() + 1, 1)
        .toLocaleString('en-US', { month: 'long' });
      ;
    },
    niceDate: function niceDate(event) {
      return `${this.getDay(
        event.metadata.date + 'T' + event.metadata.start_time
      )}
      ${this.getMonthShort(event.metadata.date + 'T' + event.metadata.start_time)}
      ${this.getDayNumeric(
        event.metadata.date + 'T' + event.metadata.start_time
      )} ● ${event.metadata.start_time} Hrs`;
    }
  }
};
