<template>
  <div
    style="margin-top: 10px">
    <div v-for="(period, _idx) in periodDates">
      <div style="padding: 2px;margin: 2px;" class="text-left">
        <p
          :style="{
              backgroundColor: `${color}`,
            }"
          style="
              font-family: 'Oswald', sans-serif;
              text-transform: uppercase;
              font-weight: bold;
              margin-bottom: 0;
            "
        >
          {{ getMonthShort(period[0].metadata.date + 'T' + '00:00') }}
        </p>
        <p
          style="
                       font-family: 'Oswald', sans-serif;

              color: silver !important;
              font-size: 1.4rem;
              background-color: black;
              font-weight: bold;
            "
        >
          {{ getDayNumeric(period[0].metadata.date + 'T' + '00:00') }}
        </p>
      </div>
      <div v-for="event in period" :key="event.slug" style="padding: 2px;margin: 2px">
        <MiniArtistCard
          :image="event.metadata.image_url"
          with-venue="true"
          :event="event"
          :key="event.slug"
        ></MiniArtistCard>
      </div>
    </div>
  </div>
</template>
<script>
import MiniArtistCard from '@/components/Cards/MiniArtistCard';
import { eventDateUtils } from '@/common/eventDateUtils';

export default {
  name: 'DjScheduleCollection',
  mixins: [eventDateUtils],
  props: {
    'desktop': {
      type: Boolean,
      default: false
    }, 'nye': {
      type: Boolean,
      default: false
    }, 'color': {
      type: String,
      default: '#202020'
    },
    'size': {
      type: Number,
      default: null,
    }
  },
  data: () => {
    return {
      showIdx: 0,
      showAction: ''
    };
  },
  computed: {
    djSchedule: {
      get() {
        return this.size ? this.$store.state.djSchedule.slice(0,this.size) : this.$store.state.djSchedule;
      }
    },
    periodDates: {
      get() {
        let _periodDates = {};
        this.djSchedule.forEach((y) => {
          if (_periodDates[y.metadata.date]) {
            _periodDates[y.metadata.date].push(y);
          } else {
            _periodDates[y.metadata.date] = [];
            _periodDates[y.metadata.date].push(y);
          }
        });
        return _periodDates;
      }
    }
  },
  created: function() {
    this.$store.dispatch('getDjSchedule');
  },
  components: {
    MiniArtistCard
  }
};
</script>
